/* General styles */

.success {
    position: fixed !important;
    right: 50px;
    bottom: 50px;
}

@font-face {
    font-family: "Brush";
    src: url("./ComicabrushRegular.woff2") format("woff2");
}

span {
    &.nowrap {
        text-wrap: nowrap;
        white-space: nowrap;
    }

    &.brush {
        font-family: "Brush";
        color: var(--primary-200);
    }
}

[data-absolute] {
    position: absolute;
}

.pointer {
    cursor: pointer;
}

.animate {
    max-height: 0;
    transition: all 0.6s ease;
    will-change: max-height;
    opacity: 0;

    &.safariMbl {
        transition: none;
    }

    &.trigger {
        max-height: 650px;
        opacity: 1;
    }
}

body {
    overflow-x: hidden;
}

#page-container {
    overflow: hidden;
}

#content-wrap {
    padding-top: var(--headerHeight);
}

.disabled {
    //opacity: 0.4;
    cursor: not-allowed;
    pointer-events: none;

    &:hover {
        animation: none;
        transition: none;
    }
}

.flex {
    display: flex;
}

.closeBlack {
    width: 16px;
    height: 16px;
    filter: invert(1);
    align-self: center;
}

/* Headings */

.text-200 {
    font-size: var(--p-size);
    font-weight: 300;
    line-height: var(--p-lh);
    color: var(--text-200);
}

h1 {
    font-size: var(--h1-size);
    font-weight: 700;
    line-height: var(--h1-lh);
    margin-bottom: var(--h1-mb);
}

h2 {
    font-size: var(--h2-size);
    font-weight: 700;
    line-height: var(--h2-lh);
    margin-bottom: var(--h2-mb);
}

h3 {
    font-size: var(--h3-size);
    line-height: var(--h3-lh);
    margin-bottom: var(--h3-mb);
    font-weight: 700;
}

h4 {
    font-size: var(--h4-size);
    font-weight: 700;
    line-height: var(--h4-lh);
    margin-bottom: var(--h4-mb);
}

h5,
ul li {
    font-size: var(--h5-size);
    font-weight: 400;
    line-height: var(--h5-lh);
    max-width: 800px;
}

h6 {
    font-size: var(--p-size);
    font-weight: 400;
    line-height: var(--p-lh);
    margin-bottom: var(--h3-mb);
    text-align: center;
}

/* Paragraphs */
p {
    font-size: var(--p-size);
    font-weight: 300;
    line-height: var(--p-lh);
    color: var(--text-200);

    &.p2 {
        font-size: var(--p2-size);
        line-height: var(--p2-lh);
    }
}

/* Images */
img {
    pointer-events: none;
    user-select: none;
}

@media screen and (min-width: 1024px) {
    h6 {
        text-align: left;
    }
}

.list {
    display: flex;
    flex-direction: column;
    gap: var(--list-gap);
}

.disabledChild {
    pointer-events: all;
    cursor: pointer;

    * {
        pointer-events: none !important;
    }
}

/* Media Queries */
@media screen and (max-width: 1023px) {
    p.no-max-tbl {
        max-width: none;
    }
}

[data-reveal] {
    opacity: 0;
    transform: translateY(40px);
    transition:
        opacity 0.4s ease,
        transform 0.4s ease;
    -webkit-backface-visibility: hidden;

    &[data-reveal="1"] {
        transition-delay: 0.1s;
    }

    &[data-reveal="2"] {
        transition-delay: 0.2s;
    }

    &[data-reveal="3"] {
        transition-delay: 0.3s;
    }

    &[data-reveal="4"] {
        transition-delay: 0.4s;
    }

    &[data-reveal="5"] {
        transition-delay: 0.5s;
    }

    &[data-reveal="6"] {
        transition-delay: 0.6s;
    }

    &[data-reveal="7"] {
        transition-delay: 0.7s;
    }

    &[data-reveal="8"] {
        transition-delay: 0.8s;
    }

    &[data-reveal="9"] {
        transition-delay: 0.9s;
    }

    &[data-reveal="10"] {
        transition-delay: 1s;
    }
}

.safari {
    [data-reveal] {
        -webkit-backface-visibility: hidden;
        opacity: 1;
        transform: none;
    }
}

.animateHeight {
    will-change: height;
}

.animated {
    opacity: 1;
    transform: none;

    transition:
        opacity 0.4s ease,
        transform 0.4s ease;
    -webkit-transform: translate3d(0, 0, 0);
    -webkit-backface-visibility: hidden;
    -webkit-transform-style: preserve-3d;
}

.accent {
    color: var(--primary-200) !important;
}

a.accent {
    text-decoration: underline;
}

.bold {
    //display: inline-block;
    font-weight: bold !important;
}

span,
a,
h5,
p {
    &.link {
        text-decoration: underline;
        cursor: pointer;
    }

    &.accent {
        color: var(--accent-100) !important;
    }

    &.telegram {
        color: var(--telegram) !important;
    }

    &.primary-100 {
        color: var(--primary-100) !important;
    }

    &.primary-200 {
        color: var(--primary-200) !important;
    }

    &.primary-300 {
        color: var(--primary-300) !important;
    }

    &.discord:not(.button) {
        color: var(--discord) !important;
    }

    &.discord-light {
        color: #8379ff !important;
    }
}

.main-sections {
    flex-direction: column;

    section {
        padding: 0 var(--mg-lr);
        //max-width: calc(1040px + var(--mg-lr) + var(--mg-lr));
        width: 100%;
        margin: 0;
        height: 100%;
        scroll-margin-top: var(--headerHeight);
        position: relative;
        padding-bottom: var(--sectionBottom);
        display: flex;
        justify-content: center;

        &:first-of-type {
            scroll-margin-top: 0;
        }

        [data-content] {
            display: flex;
            flex-direction: column;
            max-width: 1040px;
            width: 100%;
            margin: 0;
            height: 100%;
        }

        div {
            -webkit-transform: translate3d(0, 0, 0);
        }
    }
}

.info-sections {
    flex-direction: column;

    section {
        padding: 0 var(--mg-lr);
        //max-width: calc(1040px + var(--mg-lr) + var(--mg-lr));
        width: 100%;
        margin: 0;
        height: 100%;
        scroll-margin-top: var(--headerHeight);
        position: relative;
        padding-bottom: calc(var(--sectionBottom) / 2);
        display: flex;
        justify-content: center;
        max-width: 1040px;
        flex-direction: column;

        ul {
            margin: 24px;

            li {
                list-style: inside !important;
                list-style-type: circle;
                margin-bottom: 8px;
                font-size: var(--p-size);
                line-height: var(--p-lh);
            }
        }
    }
}

@media screen and (min-width: 730px) {
    .main-sections {
        [data-nomg] {
            padding-left: 0;
            padding-right: 0;
            padding-bottom: calc(var(--sectionBottom) - 24px);

            h2,
            h5 {
                padding: 0 var(--mg-lr);
            }
        }
    }
}

@media screen and (min-width: 1024px) {
    .main-sections {
        [data-nomg] {
            padding: 0 var(--mg-lr);
            padding-bottom: var(--sectionBottom);

            h2,
            h5 {
                padding: 0;
            }
        }
    }
}

@media screen and (min-width: 1180px) {

    .main-sections,
    .info-sections {
        display: flex;
        align-items: center;
        margin-left: none;

        .section {
            width: 1040px;
        }
    }
}

.lds-circle-loader {
    transform: translateZ(1px);
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.circle {
    display: inline-block;
    width: 64px;
    height: 64px;
    margin: 8px;
    border-radius: 50%;
    background: #f79f1f;
    animation: lds-circle 5.4s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

.lds-circle {
    display: inline-block;
    transform: translateZ(1px);
}

.lds-circle>div {
    display: inline-block;
    width: 64px;
    height: 64px;
    margin: 8px;
    border-radius: 50%;
    background: var(--primary-200);
    animation: lds-circle 5.4s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

@keyframes lds-circle {

    0%,
    100% {
        animation-timing-function: cubic-bezier(0.5, 0, 1, 0.5);
    }

    0% {
        transform: rotateY(0deg);
    }

    50% {
        transform: rotateY(1800deg);
        animation-timing-function: cubic-bezier(0, 0.5, 0.5, 1);
    }

    100% {
        transform: rotateY(3600deg);
    }
}

[data-loading] {
    animation: fade 2s infinite ease;
}

@keyframes fade {
    0% {
        opacity: 0;
    }

    50% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

[data-fade-in] {
    animation: fadeIn 0.4s ease;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

w3m-modal {
    background: rgba(16, 16, 18, 0.48);
    backdrop-filter: blur(24px);
}

.loader {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    display: block;
    margin: 22px auto;
    position: relative;
    color: #b6b6b6;
    box-sizing: border-box;
    animation: animloader 2s linear infinite;
}

@keyframes animloader {
    0% {
        box-shadow:
            14px 0 0 -2px,
            38px 0 0 -2px,
            -14px 0 0 -2px,
            -38px 0 0 -2px;
    }

    25% {
        box-shadow:
            14px 0 0 -2px,
            38px 0 0 -2px,
            -14px 0 0 -2px,
            -38px 0 0 2px;
    }

    50% {
        box-shadow:
            14px 0 0 -2px,
            38px 0 0 -2px,
            -14px 0 0 2px,
            -38px 0 0 -2px;
    }

    75% {
        box-shadow:
            14px 0 0 2px,
            38px 0 0 -2px,
            -14px 0 0 -2px,
            -38px 0 0 -2px;
    }

    100% {
        box-shadow:
            14px 0 0 -2px,
            38px 0 0 2px,
            -14px 0 0 -2px,
            -38px 0 0 -2px;
    }
}

.wiggle {
    animation: wiggle 0.5s ease;
}

@keyframes wiggle {
    0% {
        transform: rotate(0deg);
    }

    20% {
        transform: rotate(4deg);
    }

    40% {
        transform: rotate(-4deg);
    }

    60% {
        transform: rotate(4deg);
    }

    80% {
        transform: rotate(-4deg);
    }

    100% {
        transform: rotate(0deg);
    }
}


.link-team {
    text-decoration: underline !important;
    transition: color 0.3s ease;

    &:hover {
        color: white !important;
    }
}